.slick-slide {
  width: 1440px;
}

.slick-slide:not(.slick-active) {
  opacity: 0.5;
}

.slide-wrap:before {
  content: "";
  display: block;
  width: 12px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
}
.slide-wrap:after {
  content: "";
  display: block;
  width: 12px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #000;
}

.slick-arrow {
  width: 64px;
  height: 64px;
}

.slick-prev {
  /* left: 11.25%; */
  left: 50%;
  z-index: 1;
  transform: translate(-1170%, -50%) rotate(180deg);
}

.slick-next {
  /* right: 11.25%; */
  right: 50%;
  transform: translate(1170%, -50%);
}

.slick-arrow:before {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  background: url('../../../img/slide-arrow_2x.png') center / 64px 64px no-repeat;
  opacity: 1;
}

.slick-dots {
  display: none !important;
}

@media (max-width: 1024px) {
  .slick-list {
    max-width: 75vw;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .slick-arrow,
  .slide-wrap:before,
  .slide-wrap:after {
    display: none !important;
  }

  .slick-slide {
    max-width: 100vw;
  }

  .slick-slide:not(.slick-active) {
    opacity: 0;
    height: 0;
  }

  .slick-dots {
    display: block !important;
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }

  .slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 0;
    border: 1px solid #707070;
    background: #000;
    border-radius: 50%;
  }

  .slick-dots li button:before {
    content: "";
    display: none;
  }

  .slick-dots li.slick-active button {
    border-color: #ff6e56;
    background: #ff6e56;
  }
}