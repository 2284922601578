@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/uey2cdk.css");

@font-face {
  font-family: 'Bw Gradual';
  src: url('fonts/BwGradual-Regular.eot');
  src: url('fonts/BwGradual-Regular.eot?#iefix') format('embedded-opentype'),
      url('fonts/BwGradual-Regular.woff2') format('woff2'),
      url('fonts/BwGradual-Regular.woff') format('woff'),
      url('fonts/BwGradual-Regular.ttf') format('truetype'),
      url('fonts/BwGradual-Regular.svg#BwGradual-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Highrise';
  src: url('fonts/HighriseRegular.eot');
  src: url('fonts/HighriseRegular.eot?#iefix') format('embedded-opentype'),
      url('fonts/HighriseRegular.woff2') format('woff2'),
      url('fonts/HighriseRegular.woff') format('woff'),
      url('fonts/HighriseRegular.ttf') format('truetype'),
      url('fonts/HighriseRegular.svg#HighriseRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  @apply w-screen overflow-x-hidden text-orange;
}

[hidden] {
  display: none !important;
}

.svg-icon svg {
  @apply max-w-full max-h-full;
}

.menu-link:hover .menu-link__bg {
  @apply w-full;
}

.menu-link.highlighted {
  @apply border-orange;
  min-width: 200px;
}

.menu-link.highlighted:before,
.menu-link.highlighted:after {
  content: "";
  display: inline-block;
  width: 26px;
  height: 25px;
  background: url('img/star.svg') center no-repeat;
  position: absolute;
  left: 13px;
}
.menu-link.highlighted:after {
  left: auto;
  right: 13px;
}

.site-footer .menu-link.highlighted:before,
.site-footer .menu-link.highlighted:after {
  display: none;
}

.menu-link.active {
  @apply line-through bg-white text-black;
}
.menu-link.highlighted.active {
  @apply line-through bg-orange;
}
.menu-link.highlighted.active:before,
.menu-link.highlighted.active:after {
  background: url('img/star-black.svg') center no-repeat;
  z-index: 2;
}

.page--home {
  background: url('img/Landing-BG.jpg') top left no-repeat, #000;
  background-size: 100% auto;
}

@media (max-width: 1280px) {
  .page--home {
    background: url('img/Landing-BG.jpg') top left no-repeat, #000;
    background-size: auto 100vw;
  }
}

@media (max-width: 768px) {
  .page--home {
    background: url('img/Landing-BG.jpg') top left no-repeat, #000;
    background-size: auto 716px;
  }
}

.page--home .site-footer {
  @apply border-t border-t-white;
}

.site-heading {
  font-size: 8.7vw;
  margin-left: -1.042vw;
}

@media (max-width: 768px) {
  .site-heading {
    font-size: 3rem;
    margin-left: 0;
  }
}

.image-row__images {
  width: 102vw;
  margin-left: -1.042vw;
}

.callout-text__content {
  background: url('img/Design-Oval.svg') 113% 52% no-repeat;
}

@media (max-width: 1024px) {
  .callout-text__content {
    background: url('img/Design-Oval.svg') 0 22% no-repeat;
    background-size: 122% auto;
  }  
}

.callout-text__title {
  word-spacing: 1.25rem;
}

.row-of-cards__card:after {
  content: "";
  display: block;
  width: 39px;
  height: 2px;
  background: url('img/gradient-line.svg') center no-repeat;
  position: absolute;
  right: -48px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1024px) {
  .row-of-cards__card:after {
    content: "";
    display: block;
    width: 39px;
    height: 2px;
    background: url('img/gradient-line.svg') center no-repeat;
    position: absolute;
    right: auto;
    left: 50%;
    top: auto;
    bottom: -48px;
    transform: translateX(-50%) rotate(90deg);
  }
}

.row-of-cards__card:last-child:after,
.stats__card:last-child:after {
  display: none;
}

.row-of-cards__title em {
  @apply not-italic underline;
}

.on-screen .row-of-cards__card {
  @apply bg-charcoal transition-all duration-1000 ease-in-out;
}
.on-screen .row-of-cards__card strong,
.on-screen .row-of-cards__card em {
  @apply text-orange transition-all duration-1000 ease-in-out;
}
.on-screen .row-of-cards__card p {
  @apply text-white transition-all duration-1000 ease-in-out;
}

.row-of-cards__card .svg-icon {
  @apply filter grayscale transition-all duration-1000 ease-in-out opacity-40;
}

.row-of-cards__card.card-1 .svg-icon {
  @apply delay-1000;
}
.row-of-cards__card.card-2 .svg-icon {
  @apply delay-2000;
}

.on-screen .row-of-cards__card .svg-icon {
  filter: grayscale(0);
  @apply opacity-100;
}

.on-screen .row-of-cards__card.card-1,
.on-screen .row-of-cards__card.card-1 strong,
.on-screen .row-of-cards__card.card-1 em,
.on-screen .row-of-cards__card.card-1 p {
  @apply delay-1000;
}

.on-screen .row-of-cards__card.card-2,
.on-screen .row-of-cards__card.card-2 strong,
.on-screen .row-of-cards__card.card-2 em,
.on-screen .row-of-cards__card.card-2 p {
  @apply delay-2000;
}

.on-screen .row-of-cards__card strong,
.on-screen .row-of-cards__card em {
  @apply text-orange;
}
.on-screen .row-of-cards__card p {
  @apply text-white;
}

@media (max-width: 1024px) {
  .row-of-cards__card {
    @apply bg-charcoal;
  }
  .row-of-cards__card strong,
  .row-of-cards__card em {
    @apply text-orange;
  }
  .row-of-cards__card p {
    @apply text-white;
  }

  .row-of-cards__card .svg-icon {
    filter: grayscale(0);
    @apply opacity-100;
  }

  .row-of-cards__card strong,
  .row-of-cards__card em {
    @apply text-orange;
  }
  .row-of-cards__card p {
    @apply text-white;
  }
}

.stats__card:after {
  content: "";
  display: block;
  width: 2px;
  height: 246px;
  background: url('img/gradient-line-vertical.svg') center no-repeat;
  position: absolute;
  right: -148px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1024px) {
  .stats__card:after {
    content: "";
    display: block;
    width: 2px;
    height: 246px;
    background: url('img/gradient-line-vertical.svg') center no-repeat;
    position: absolute;
    right: auto;
    left: 50%;
    top: auto;
    bottom: -172px;
    transform: translateX(-50%) rotate(90deg);
  }
}

/* .stat__count {
  background: -webkit-linear-gradient(#ff6e56, #000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.featured-work__scroll-font {
  width: 97.5vw;
  height: 28px;
  background: url('img/Small-Font-Scroll-Right_4x.png') left repeat-x;
  background-size: auto 100%;
  margin: 0 auto;
}

.featured-work__left .project-0 img {
  width: 687px;
}
.featured-work__left .project-1 img {
  width: 620px;
  margin-top: 344px;
}
.featured-work__left .project-2 img {
  width: 480px;
  margin-top: 350px;
}

.featured-work__right .project-0 img {
  width: 600px;
  margin-top: 352px;
}
.featured-work__right .project-1 img {
  width: 700px;
  margin-top: 442px;
}

@media (max-width: 1024px) {
  .featured-work__left .featured-work__project img,
  .featured-work__right .featured-work__project img {
    margin-top: 100px;
  }
}

.component--images-text-cta {
  background: url('img/gradient-bg-2.png') center no-repeat;
  background-size: 100% 100%;
}

.images-text-cta__copy p {
  @apply font-normal text-lg lg:text-[1.375rem] leading-6 lg:leading-normal mb-5 lg:mb-10 xl:max-w-[525px];
}

.images-text-cta__copy p + p {
  @apply text-base lg:text-lg leading-normal lg:leading-close italic lg:not-italic xl:max-w-[501px];
}

/* .left-image-with-text__text p {
  @apply xl:max-w-350px;
} */

.left-image-with-text__text p {
  @apply mt-4 xl:mt-12 text-lg leading-6 lg:text-lg xl:max-w-[332px];
}

.text-box__columns {
  grid-template-columns: 0.42fr 0.46fr;
}

.text-box__column:nth-child(2n) {
  @apply text-lg;
}

.text-box__column p {
  @apply mb-6;
}

.services__service {
  grid-template-columns: 0.63fr 0.32fr;
}

.services__service ul li:before {
  content: "\25AA";
  display: inline-block;
  margin-right: 5px;
}

.component--cta-tile {
  background: url('img/gradient-bg.png') center no-repeat;
  background-size: 100% 100%;
}

.case-studies-list__featured .project--small {
  @apply w-[480px];
}

.case-studies-list__featured .project--large {
  @apply w-[600px];
}

.flip-card,
.featured-project {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card.project--large,
.featured-project.project--large {
  @apply w-[296px] h-[296px] lg:w-[600px] lg:h-[600px];
}
.flip-card.project--small,
.featured-project.project--small {
  @apply w-[296px] h-[296px] lg:w-[480px] lg:h-[480px];
}

.flip-card-inner,
.featured-project-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back,
.featured-project-front,
.featured-project-back {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

/* FEATURED PROJECT - DEFAULT */
/* .featured-project-back {
  transition: transform 0.8s, background 0.8s, width 0.8s;
} */

.featured-project:hover .featured-project-back {
  @apply left-0;
}

.loading__first {
  @apply transition-all duration-1000 transform;
  animation: slideUp 1s forwards;
}

.loading__second {
  @apply transition-all duration-1000 transform;
  animation: slideUp2 1s forwards;
}

.unloading__first {
  @apply transition-all duration-1000 transform;
  animation: slideUp3 1s forwards;
}

.unloading__second {
  @apply transition-all duration-1000 transform;
  animation: slideUp4 1s forwards;
}

/* .project__hero:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
} */

@media (max-width: 1024px) {
  .experience--initial__heading br {
    display: none;
  }
}

/* ANIMATIONS */
@media (min-width: 1025px) {
  .animate {
    @apply transition-all transform duration-700;
  }

  .slide-from-top {
    @apply opacity-0 -translate-y-12;
  }
  .slide-from-bottom {
    @apply opacity-0 translate-y-12;
  }
  .slide-from-left {
    @apply opacity-0 -translate-x-12;
  }
  .slide-from-right {
    @apply opacity-0 translate-x-12;
  }
  .fade-in-grow {
    @apply opacity-0 scale-90;
  }
  .fade-in {
    @apply opacity-0;
  }

  .on-screen .slide-from-top {
    @apply opacity-100 translate-y-0;
  }
  .on-screen .slide-from-bottom {
    @apply opacity-100 translate-y-0;
  }
  .on-screen .slide-from-left {
    @apply opacity-100 translate-x-0;
  }
  .on-screen .slide-from-right {
    @apply opacity-100 translate-x-0;
  }
  .on-screen .fade-in-grow {
    @apply opacity-100 scale-100;
  }
  .on-screen .fade-in {
    @apply opacity-100;
  }

  .text-rise__text {
    @apply translate-y-full transition-all duration-1000 ease-in-out;
  }
  
  .on-screen .text-rise__text {
    @apply translate-y-0;
  }
}

/* Keyframe Animations */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp2 {
  0% {
    transform: translateY(100%);
  }

  35% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp3 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes slideUp4 {
  0% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}
